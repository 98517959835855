.date {
  font-family: 'DM Sans';
  font-weight: normal;
  font-size: 1rem;
  color: var(--brand-white) !important;
  line-height: 27px;
  margin-top: 0.22rem;
  margin-bottom: 0;
}

.wrapper {
  margin-right: 0;
  width: auto;
}

@media screen and (min-width: 480px) {
  .date {
    font-size: 1.25rem;
  }
}