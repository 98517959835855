.bidRow {
  border-radius: 4px;
  height: 96px;
  width: 100%;
  padding: 16px;
  border-bottom: 0px;
  margin-bottom: 16px;
  background-color: #EEF2F6;
}

.bidderInfoWrapper {
  display: flex;
  align-items: center;;
}

.bidderInfoText {
  margin-left: 0.5rem;
  display: inline-block;
  padding: 0;
  line-height: 23px;
  color: var(--brand-bg-gray) !important;
}

.bidDate {
  color: rgba(140, 141, 146, 1);
  font-weight: 500;
  font-family: 'DM Sans';
  font-size: 13px;
}

.trophy {
  margin-left: 0.5rem;
  margin-bottom: 0.1rem;
}

.linkIcon {
  color: var(--brand-bg-gray);
  transition: all 0.125s ease-in-out;
  margin-bottom: 0.1rem;
  font-weight: normal;
}

.linkIcon:hover {
  color: black;
  cursor: pointer;
}

.bidAmount {
  white-space: nowrap;
  color: var(--brand-bg-gray) !important;
  font-weight: normal;
}
