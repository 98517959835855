.altWrapper {
  text-align: center;
}

.bidCollection {
  display: grid;
  text-align: start;
  list-style-type: none;
  row-gap: 0.5rem;
  padding: 0;
}

.bidRowCool {
  font-family: 'DM Sans', sans-serif;
  height: 56px;
  border-bottom: 1px solid var(--brand-warm-light-text);
  font-size: 1.1rem;
  font-weight: bold;
  transition: all 0.2s ease-in-out;
  display: flex;
}


.bidRowWarm {
  font-family: 'DM Sans', sans-serif;
  padding: 0.25rem 0.75rem 0.5rem 0.75rem;
  border-bottom: 1px solid var(--brand-warm-border);
  font-size: 0.95rem;
  font-weight: bold;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
}

.bidRowWarm a {
  color: var(--brand-color-red) !important;
  font-weight: bold;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}

.bidRowCool a {
  color: var(--brand-color-blue) !important;
  font-weight: bold;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}

.bidRowWarm a:hover,
.bidRowCool a:hover {
  filter: brightness(110%);
}

.bidItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.leftSectionWrapper {
  display: flex;
  flex-direction: column;
}

.bidder {
  font-family: 'DM Sans';
  font-weight: bold;
  color: var(--brand-white);
}

.bidder > div > div {
  font-family: 'DM Sans';
  font-weight: bold;
  font-size: 1.25rem;
}

.bidRowCool .bidder > div > div {
  color: var(--brand-white);
}

.bidRowWarm .bidder > div > div {
  color: var(--brand-white);
}

.bidDate {
  display: none;
  color: gray;
}

.rightSectionWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.bidAmount {
  font-family: 'DM Sans';
  font-weight: bold;
  margin-right: 1rem;
  font-size: 1.25rem;
  padding-top: 2px;
}


.linkSymbol {
  margin-top: -4px;
}

.bidRowCool .bidAmount,
.bidRowWarm .bidAmount,
.linkSymbol {
  color: var(--brand-white);
}
.linkSymbol:hover {
  color: var(--brand-white) !important;
}

.linkSymbol a,
.linkSymbol a:hover,
.linkSymbol:hover a {
  color: inherit !important;
}

@media (max-width: 992px) {
  .bidRow {
    border-radius: 10px;
  }

  .bidAmount .linkSymbol .bidder {
    font-size: 16px;
  }
}
.noBids {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: var(--brand-bg-gray-400);
}
.noBids h1 {
  font-size: 1.5rem;
  font-weight: normal;
  margin: 0;
}
.noBids p {
  color: var(--brand-warm-light-text);
  font-size: 1.25rem;
  margin: 0;
  padding: 0 10%;
}