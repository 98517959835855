.title {
  font-family: 'DM Sans';
  font-weight: bold;
  font-size: 1.25rem;
  color: var(--brand-warm-light-text);
}
.nounAddress {
  font-family: 'DM Sans';
  font-weight: bold;
  font-size: 1.25rem;
  color: var(--brand-white);
}

.nounHolderEtherscanLinkCool {
  font-family: 'DM Sans';
  font-weight: bold;
  color: var(--brand-color-white);
  text-decoration: none;
  transition: all 0.15s ease-in-out;
}

.nounHolderEtherscanLinkWarm {
  font-family: 'DM Sans';
  font-weight: bold;
  color: var(--brand-color-red);
  margin-left: 5px;
  text-decoration: none;
  transition: all 0.15s ease-in-out;
}

.nounHolderEtherscanLinkWarm:hover {
  color: var(--brand-color-red) !important;
  filter: brightness(110%);
}

.nounHolderEtherscanLinkCool:hover {
  color: var(--brand-color-blue) !important;
  filter: brightness(110%);
}

.nounHolderInfoContainer {
  display: inline;
  width: 350px;
  height: 35px;
}

.nounHolderLoading {
  opacity: 0.5;
}

.linkIconSpan {
  margin-left: 5px;
}

.linkIcon {
  margin-bottom: 5px;
}
