.woodWrapper {
  background-image: url(../../assets/auction-bg@1x_01.png);
  background-repeat: repeat;
  background-size: 20%;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.header h2 {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin-top: 16px;
  margin-bottom: 0;
}
.header h1 {
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  margin-bottom: 0;
}
.header p {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #9AA3B2;
  margin: 0 0 40px;
  padding: 0;
}
.wrapper {
  padding-right: var(--bs-gutter-x,1rem);
  padding-left: var(--bs-gutter-x,1rem);
}
.wrapperTeam {
  display: flex;
  align-items: stretch;
}
.colTeam {
  display: flex;
  flex-direction: column;
}
.westernSide .bidButtonSide:hover,
.westernSide .bidButtonSide:active,
.westernSide .bidButtonSide:focus .bidButtonSide:disabled {
  background-color: var(--brand-color-blue) !important;
}

.easternSide .bidButtonSide,
.easternSide .bidButtonSide:hover,
.easternSide .bidButtonSide:active,
.easternSide .bidButtonSide:focus .bidButtonSide:disabled {
  background-color: var(--brand-color-red) !important;
}

.conferenceTitleWrapper {
  z-index: 2;
  position: relative;
}
.conferenceTitle {
  padding-left: 24px;
  height: 80px;
  line-height: 80px;
  color: var(--brand-white);
  text-align: left;
  font-size: 3rem;
  font-weight: bold;
  border-top-left-radius: var(--default-border-radius);
  border-top-right-radius: var(--default-border-radius);
  background-color: var(--brand-bg-gray-light);
  border-bottom-width: 8px;
  border-bottom-style: solid;
}

.eastSide {
  color: var(--brand-color-blue);
  border-color: var(--brand-color-blue);
}
.westSide {
  color: var(--brand-color-red);
  border-color: var(--brand-color-red);
}

.teamList {
  width: 100%;
  margin: 0;
  padding: 8px 14px;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  flex-grow: 1;
}
.bgEastSide {
  background-color: var(--brand-color-blue);
}
.bgWestSide {
  background-color: var(--brand-color-red);
}
.team {
  list-style: none;
  background-color: var(--brand-bg-gray-light);
  border-radius: 4px;
  border-radius: 4px;
  width: 31%;
  margin-right: 3%;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
}
.team:nth-child(3n) {
  margin-right: 0;
}
.teamInfo {
  padding: 8px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.teamInfoTitle h2 {
  font-size: 1rem !important;
  line-height: 1.3rem !important;
  font-weight: 700 !important;
  margin-top: 16px !important;
}
.teamInfo h1 {
  font-size: 1.2rem !important;
  line-height: 1.5rem !important;
  margin-bottom: 8px !important;
}
.teamInfoHolder h2 {
  font-size: 0.8rem !important;
  line-height: 1rem !important;
  font-weight: 400 !important;
}
.nounbaPic {
  width: 100%;
  max-height: none !important;
  min-height: auto !important;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.bidHistory {
  font-family: 'DM Sans';
  color: var(--brand-white);
  width: 100%;
  height: 56px;
  font-weight: normal;
  letter-spacing: normal;
  font-size: 1.2rem;
  background-color: var(--brand-gray-background);
  border: none;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  margin-top: 16px;
  border-radius: 4px;
  align-self: flex-end;
}


.bidHistory:hover,
.bidHistory:active,
.bidHistory:focus {
  background-color: var(--brand-white);
  color: var(--brand-black);
}


@media (max-width: 960px) {
  .bidHistory {
    font-size: 1rem;
  }
}
@media (max-width: 530px) {
  .team,
  .team:nth-child(3n) {
    width: 48%;
    margin-right: 3%;
  }
  .team:nth-child(2n) {
    margin-right: 0;
  }
}
