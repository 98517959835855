.wrapper {
  background-color: var(--brand-bg-gray-light);
  color: var(--brand-white);
  padding: 32px;
}
.backButton,
.backButton:hover,
.backButton:active {
  background-color: var(--brand-gray-background);
  color: var(--brand-white);
  -webkit-appearance: none;
  padding: 0;
  display: inline-block;
  width: 56px;
  height: 56px;
  border-radius: 4px;
  font-weight: bold;
  margin-right: 1rem;
  margin-top: 0.1rem;
}

.headerRow span {
  color: var(--brand-white);
  font-size: 24px;
  font-family: 'DM Sans';
}
.title {
  color: var(--brand-white);
  font-size: 32px;
  font-family: 'DM Sans';
  margin-top: 16px;
  margin-bottom: 16px;
}

.proposalStatus {
  margin-left: 0.75rem;
  margin-top: 0.1rem;
}

.helperText,
.helperTextSmall {
  color: var(--brand-bg-gray-500);
  font-family: 'DM Sans';
  font-weight: 400;
  font-size: 20px;
  min-width: 12rem;
  text-align: right;
  margin-right: 1rem;
}

.helperTextSmall {
  font-size: 16px;
}

.noVotesText {
  color: var(--brand-gray-light-text);
  font-family: 'DM Sans';
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 1.15rem;
  min-width: 9.5rem;
}

.submitBtn {
  width: 100%;
  max-width: 8rem;
  min-width: 8rem;
  height: 3rem;
  font-weight: 400;
  margin-bottom: 0;
  border-radius: 4px;
  background-color: var(--brand-color-blue);
  border: var(--brand-color-blue);
}
.submitBtn:focus,
.submitBtn:hover {
  background-color: var(--brand-color-blue) !important;
}
.submitBtn:active {
  background-color: var(--brand-color-blue) !important;
}
.submitBtnDisabled {
  background-color: var(--brand-bg-gray-light) !important;
  color: var(--brand-bg-gray-400);
  width: 100%;
  max-width: 170px;
  min-width: 170px;
  margin-left: 16px;
  height: 56px;
  font-weight: 400;
  font-size: 20px;
  border: none;
  box-shadow: none !important;
}

.submitBtnDisabled:hover,
.submitBtnDisabled:active,
.submitBtn:focus {
  cursor: not-allowed;
  box-shadow: none !important;
}

.proposalTitleWrapper {
  display: flex;
  padding-right: 2rem;
}

.proposalTitle {
  margin-right: 0.5rem;
}

.submitProposalButton {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: flex-end;
  padding: 32px;
  border: 1px solid #364152;
  border-radius: 4px;
  margin: 18px 0;
}

.voterIneligibleAlert {
  font-family: 'DM Sans';
  font-weight: 500;
  background-color: #e2e3e8;
  color: black;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 0.5rem;
}

.byLineWrapper {
  display: flex;
  flex-direction: row;
}

.byLineWrapper h3 {
  font-family: 'DM Sans';
  color: var(--brand-gray-light-text);
  margin-left: 72px;
  margin-bottom: 0;
  font-size: 24px;
}

.byLineContentWrapper {
  display: flex;
  flex-direction: row;
  margin-left: 0;
}

.propTransactionWrapper {
  margin-left: 0.33rem;
  margin-right: 0.5rem;
  font-size: 18px;
  color: var(--brand-gray-light-text);
}
.propTransactionWrapperJp {
  margin-left: 0.37rem;
  margin-right: 0.25rem;
  color: var(--brand-gray-light-text);
}

.proposedByJp {
  color: var(--brand-white);
}

.proposerLinkJp {
  margin-right: 0.25rem;
}

.proposalByLineWrapperJp {
  font-weight: 500;
  margin-left: 2.5rem;
}

.propTransactionHash {
  margin-left: 0.2rem;
}

.byLineContentWrapper h3 {
  margin-left: 0.5rem;
}

@media (max-width: 991px) {
  .byLineWrapper {
    flex-direction: column;
  }
  
  .proposalByLineWrapperJp {
    flex-direction: column;
    display: flex;
  }

  .proposerJp {
    margin-left: 0.5rem;
  }

  .proposedByJp {
    margin-left: 0.5rem;
  }
  .backButton {
    width: 40px;
    height: 40px;
  }
  .headerRow span {
    margin-top: 4px;
    display: inline-block;
  }
  .proposalTitleWrapper {
    padding-right: 0;
  }
  .proposalTitle {
    margin-right: 0;
  }
  .title {
    font-size: 28px;
  }
  .wrapper {
    padding: 28px;
  }
  .byLineWrapper h3 {
    margin-left: 0;
    font-size: 20px;
  }
}
