.wrapper {
  display: flex;
  flex-direction: column;
  min-width: 40%;
}
.section > span {
  font-size: 3rem;
  font-weight: bold;
  font-family: 'DM Sans';
}

.section h4 {
  font-family: 'DM Sans';
  font-weight: bold;
  font-size: 1.25rem;
}

.section h2 {
  font-family: 'DM Sans';
  font-weight: bold;
  font-size: 32px;
  margin-bottom: 0px !important;
}