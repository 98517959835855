.wrapper {
  background-image: url(../../assets/auction-bg@1x_01.png);
  background-repeat: repeat;
  background-size: 20%;
}
.westernSide .bidButtonSide:hover,
.westernSide .bidButtonSide:active,
.westernSide .bidButtonSide:focus .bidButtonSide:disabled {
  background-color: var(--brand-color-blue) !important;
}

.easternSide .bidButtonSide,
.easternSide .bidButtonSide:hover,
.easternSide .bidButtonSide:active,
.easternSide .bidButtonSide:focus .bidButtonSide:disabled {
  background-color: var(--brand-color-red) !important;
}
