.createProposalPage a {
  color: var(--brand-white);
}

.createProposalForm {
  border-radius: 4px;
  padding: 1rem 2.5rem;
  margin-top: 1em;
  background-color: var(--brand-bg-gray-light);
}

.heading {
  margin: 1rem 0;
}

.section {
  border-top: 1px solid #e9ecef;
  word-wrap: break-word;
  padding-top: 2rem;
  margin-top: 2rem;
}

.addTransactionButton,
.addTransactionButton:hover,
.createProposalButton,
.createProposalButton:hover {
  margin-top: 1rem;
  border-radius: 4px;
  background-color: var(--brand-gray-background);
  border-color: var(--brand-gray-background);
}
