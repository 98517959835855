.navBarBrand {
  position: relative;
  z-index: 2;
  padding: 24px 0;
  margin-left: 20px;
  transition: all 0.15s ease-in-out;
  display: flex;
  align-items: center;
  font-family: 'DM Sans';
  font-size: 40px;
}

.navBarBrand:hover {
  transform: scale(0.95);
}

.navBarLogo {
  width: 210px;
  height: 60px;
}

.navLogoTitle {
  display: inline-block;
  margin-left: 10px;
  margin-bottom: 0;
  font-size: 2.5rem;
}

.navBarCustom {
  padding-bottom: 0;
  padding-top: 0;
  background-color: var(--brand-bg-gray);
}

@media (max-width: 479px) {
  .navLogoTitle {display:none}
}

/* connect button displays differently to default NavBarItems, thus the !importants */
.connectBtn {
  cursor: pointer;
}

.connectBtn:hover,
.connectBtn:active,
.connectBtn:focus {
  cursor: pointer !important;
}

.disconnectBtn {
  cursor: pointer;
  font-family: 'DM Sans';
  font-weight: bold;
}

.disconnectBtn:hover,
.disconnectBtn:active,
.disconnectBtn:focus {
  cursor: pointer !important;
}

.greenStatusCircle {
  display: relative;
  width: 10px;
  height: 10px;
  background-color: #7fc59c;
  border-radius: 50%;
  margin-right: -30px;
  margin-left: 20px;
  margin-top: -20px;
  z-index: 3;
}

.nounsNavLink,
.nounsNavLink:active,
.nounsNavLink:visited {
  font-family: 'DM Sans';
  font-size: 0.9rem;
  padding: 0.3rem !important;
  color: white !important;
}

.testnetImg {
  width: auto;
  height: 45px;
}

.addressNavLink {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.brandAndTreasuryWrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}

.navBarToggle {
  border-radius: var(--default-border-radius);
  height: 56px;
  width: 56px;
  margin-right: 20px;
  background-color: var(--brand-color-blue);
}
.navBarToggle span {
  background-image: url("./../../assets/toggle-menu.svg") !important;
}

.navbarCollapse {
  flex-grow: unset;
  padding-bottom: 15px;
}
.testnet {
  background-color: red;
  color: white;
  font-size: 12px;
  font-weight: bold;
}
.testnet img {
  margin: 0 20px 0 10px;
}


.treasuryAndNavWrapper {
  display: flex;
}
.treasuryLink {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
@media screen and (min-width: 992px) {
  .navBarBrand {
    padding: 16px 0;
  }
  .navbarCollapse{
    padding-bottom: 0;
  }
}