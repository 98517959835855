.noProposals {
  background-color: #202939;
  color: var(--brand-white);
  border: none;
}
.proposals {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--brand-gray-background);
  border-radius: var(--default-border-radius);
  margin-bottom: 32px;
  padding: 32px;
}

.proposals > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.heading {
  font-family: 'DM Sans';
  font-size: 25px;
  margin: 0 !important;
}

.generateBtn {
  max-width: 12rem;
  padding: 0 20px;
  height: 56px;
  font-weight: bold;
  margin-bottom: 0.5rem;
  border-radius: var(--default-border-radius);
  background-color: var(--brand-color-blue);
  border: var(--brand-color-blue);
}
.generateBtn:focus,
.generateBtn:hover {
  background-color: var(--brand-color-blue) !important;
}
.generateBtn:active {
  background-color: var(--brand-color-blue) !important;
}

.generateBtnDisabled {
  max-width: 13rem;
  height: 56px;
  font-weight: bold;
  border-radius: var(--default-border-radius);
  background-color: var(--brand-bg-gray-light);
  color: var(--brand-bg-gray-400);
  border: 1px solid var(--brand-bg-gray-light);
  font-size: 20px;
  font-weight: 400;
  font-family: 'DM Sans';
  padding: 0 20px;
}
.generateBtnDisabled:focus,
.generateBtnDisabled:hover,
.generateBtnDisabled:active {
  cursor: not-allowed;
  background-color: var(--brand-bg-gray-light) !important;
  border-color: var(--brand-bg-gray-light) !important;
  color: var(--brand-bg-gray-400) !important;
  outline: none;
  box-shadow: none;
}

.proposalLink {
  padding: 1rem;
  margin-top: 0.4rem;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border-radius: var(--default-border-radius);
  background: var(--brand-bg-gray-light);
  font-size: 25px;
  font-family: 'DM Sans';
  font-weight: 400;
}

.proposalInfoWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.proposalLink:hover {
  /* background: white; */
  cursor: pointer;
}

.markdown h1 {
  font-size: 1.7rem;
  margin-top: 1rem;
  font-weight: bold;
}

.markdown h2 {
  font-size: 1.5rem;
  margin-top: 1rem;
  font-weight: bold;
}

.markdown h3 {
  font-size: 1.3rem;
}

.markdown img {
  max-width: 100%;
  height: auto;
}

.nullStateCopy {
  margin-top: 0.9rem;
  margin-right: 1rem;
  font-family: 'DM Sans';
  font-weight: 500;
  color: var(--brand-bg-gray-500);
  font-size: 20px;
  line-height: 24px;
}

.mobileCountdownWrapper {
  display: none;
}

.desktopCountdownWrapper {
  display: flex;
}

.nounInWalletBtnWrapper {
  display: flex;
  padding-top: 0.8rem;
}

.nullBtnWrapper {
  min-width: 13rem;
}

.proposalId {
  color: var(--brand-white);
  background-color: var(--brand-gray-background);
  border-radius: 48px;
  width: 64px;
  height: 64px;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.proposalTitle {
  width: 80%;
  display: flex;
  align-items: center;
}

.proposalStatusWrapper {
  margin-left: 0.5rem;
  min-width: max-content;
}

.submitProposalButtonWrapper {
  text-align: right;
}

.nullStateSubmitProposalBtnWrapper {
  text-align: right;
}

.votePillWrapper {
  max-width: 5rem;
}

.countdownPill {
  background-color: var(--brand-bg-gray-500);
  color: var(--brand-white);
  width: fit-content;
  margin-left: -0.5rem;
}

.countdownPillContentWrapper {
  display: flex;
  flex-direction: row;
}

.countdownPillClock {
  display: flex;
  flex-direction: column;
  margin-top: auto;
  margin-bottom: auto;
}

.countdownPillText {
  margin-left: 0.25rem;
}

.delegateBtnWrapper {
  padding-left: 1rem;
}

.changeDelegateBtn {
  font-weight: bold;
  margin-bottom: 0.5rem;
  border-radius: var(--default-border-radius);
  padding: 0 20px;
  height: 56px;
  border: 1px solid var(--brand-gray-background);
  color: var(--brand-white);
  background-color: var(--brand-gray-background);
}

.changeDelegateBtn:focus,
.changeDelegateBtn:hover {
  background-color: var(--brand-gray-background);
  box-shadow: none;
  border-color: var(--brand-gray-background);
}
.changeDelegateBtn:active {
  background-color: #e2e3e8 !important;
}

@media (max-width: 992px) {
  .desktopCountdownWrapper {
    display: none;
  }

  .mobileCountdownWrapper {
    display: flex;
    width: 100%;
    margin-top: 1rem;
  }

  .nullStateCopy {
    margin-top: 0rem;
  }

  .proposalTitle {
    overflow-wrap: break-word;
    width: 100%;
    font-size: 20px;
    align-items: flex-start;
  }

  .nounInWalletBtnWrapper {
    width: 100%;
  }

  .headerWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .heading {
    width: 100%;
  }
  .nullStateSubmitProposalBtnWrapper {
    flex-direction: column-reverse;
    width: 100%;
  }  
  .generateBtnDisabled,
  .generateBtn,
  .changeDelegateBtn {
    width: 100%;
    max-width: unset;
  }
  .delegateBtnWrapper {
    padding: 1rem 0;
  }
  .proposalInfoWrapper {
    flex-direction: column;
    align-items: flex-start;
  }
  .proposalId {
    width: 34px;
    height: 34px;
    font-size: 18px;
    margin: 3px 11px 0 0;
  }
  .votePillWrapper {
    margin-left: 0;
    margin-right: 10px;
  }
}
