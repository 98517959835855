@import './css/colors.css';

.wrapper {
  background-color: var(--brand-bg-gray);
  color: var(--brand-white);
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 100vw;
  overflow-x: hidden;
}
