.spinner {
  display: flex;
  justify-content: center;
}

.wrapper {
  display: flex;
  flex-direction: row;
  margin-bottom: 1.75rem;
  justify-content: space-between;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.delegateCandidateInfoWrapper {
  display: flex;
}

.avatarWrapper {
  margin-right: 1rem;
}

.ensText {
  color: var(--brand-cool-dark-text);
  font-weight: bold;
  font-size: 22px;
}

.shortAddress {
  font-weight: 500;
  font-size: 13px;
  color: var(--brand-cool-light-text);
}
