.nounWrapper {
  width: 100%;
  max-height: 38vh;
  min-height: 300px;
  position: relative;
  margin-top: 30px;
}
.infoWrapper {
  position: relative;
  background-color: var(--brand-bg-gray);
  padding-top: 40px;
  text-align: left;
}
.infoWrapper::before {
  content: " ";
  background-color: var(--brand-bg-gray);
  position: absolute;
  left: -100vw;
  right: -100vw;
  top: 0;
  bottom: 0;
  z-index: 0;
}
.auctionInfo {
  z-index: 1;
  position: relative;
}

.nounContentCol {
  display: flex;
}

.auctionActivityCol {
  padding-right: 5rem;
  padding-bottom: 0rem;
  min-height: 520px;
  align-self: flex-end !important;
}

.conferenceTitleWrapper {
  z-index: 2;
  position: relative;
}
.conferenceTitle {
  margin: 0 0 -40px;
  padding-left: 24px;
  height: 80px;
  line-height: 80px;
  color: var(--brand-white);
  text-align: left;
  font-size: 3rem;
  font-weight: bold;
  border-radius: var(--default-border-radius);
  background-color: var(--brand-bg-gray-light);
  border-bottom-width: 8px;
  border-bottom-style: solid;
}

.eastSide {
  color: var(--brand-color-blue);
  border-color: var(--brand-color-blue);
}
.westSide {
  color: var(--brand-color-red);
  border-color: var(--brand-color-red);
}

@media (max-width: 992px) {
  .nounWrapper {
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
  }
  .auctionActivityCol {
    padding-top: 5%;
    padding-right: 5%;
    padding-left: 5%;
    width: 100%;
    background-color: white;
  }
}

@media (max-width: 568px) {
  .auctionActivityCol {
    width: 100%;
    margin-left: unset;
    margin-right: unset;
    padding-bottom: 2rem;
    padding-top: 2rem;
    padding-right: unset;
    padding-left: 0;
  }

  .nounWrapper {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 2rem;
  }

  .nounContentCol {
    padding: 0rem;
  }
}
