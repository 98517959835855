.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(18, 25, 38, 0.8);
  backdrop-filter: blur(10px);
}

.modal {
  font-family: 'DM Sans', sans-serif;
  font-weight: bold;
  position: fixed;
  top: 17vh;
  left: 10%;
  z-index: 100;
  background-color: var(--brand-white);
  padding: 32px;
  text-align: left;
  border-radius: 4px;
  left: calc(50% - 17.5rem);
  width: 35rem;
}

.modal button {
  border-radius: 4px;
  min-height: 44px;
  padding-top: 4px;
  background-color: #EEF2F6;
  border: 1px solid #EEF2F6;
  transition: all 0.2s ease-in-out;
}

.modal button:hover {
  color: var(--brand-black);
  background-color: var(--brand-white);
  border-color: var(--brand-black);
}

.modal h3 {
  font-size: xx-large;
  font-weight: bold;
}

.content {
  padding: 1rem 0;
  max-height: 50vh;
  overflow-y: auto;
}

.modal .closeButton {
  position: absolute;
  top: 2rem;
  right: 2rem;
  width: 44px;
  height: 44px;
  padding-top: 0;
}

.modal .closeButton img {
  height: 1rem;
  width: 1rem;
  opacity: 0.5;
  transition: all 0.125s ease-in-out;
  margin-top: -4px;
}

.modal .closeButton:hover img {
  opacity: 1;
}

.modal button img {
  margin-top: -2px;
  width: 64px;
  height: auto;
}

@media (max-width: 992px) {
  .modal {
    top: auto;
    left: 0;
    bottom: 0;
    width: 100%;
    max-height: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
