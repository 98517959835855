.wrapper {
  height: 56px;
  border-radius: 4px;
  font-size: 16px;
  font-family: 'DM Sans';
  font-weight: bold;
  padding: 0 16px;
  transition: all 0.125s ease-in-out;
  box-shadow: none;
  border: none;
  border: 1px solid var(--brand-gray-background);
}

.button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.treasuryHeader {
  font-size: 1.5rem;
  margin-right: 8px;
}

.warmInfo .icon {
  color: #b6a9a7;
  border-left: 1px solid #e1d7d5;
}

.treasuryBalance {
  font-size: 1.5rem;
}

@media (max-width: 480px) {
  .treasuryHeader {
    display: none;
  }
}

.warmInfo .treasuryBalance {
  color: var(--brand-white);
}

.coolInfo .treasuryBalance {
  color: var(--brand-cool-dark-text);
}

.button:hover {
  cursor: pointer;
}

.coolInfo {
  border: 1px solid var(--brand-cool-border);
  border-radius: 10px;
  color: var(--brand-cool-dark-text);
}

.coolInfo:hover {
  background-color: var(--brand-cool-accent);
  color: black;
}

.warmInfo {
  color: var(--brand-bg-gray-400);
  background-color: transparent !important;
  font-weight: normal;
}

.whiteInfo {
  background: white;
}

.whiteTreasuryHeader {
  opacity: 0.5;
}

.whiteInfo:hover {
  background-color: #e2e3e8;
}
.etherSymbol {
  font-family: sans-serif;
}