.wrapper {
  display: inline-block;
}

.wrapper h1 {
  font-family: 'DM Sans';
  font-size: 2.5rem;
  line-height: 3rem;
  margin-bottom: 24px;
  margin-top: 0;
}

.wrapper h2 {
  font-family: 'DM Sans';
  font-size: 1.5rem;
  line-height: 2rem;
  color: var(--brand-warm-light-text);
  margin-top: 18px;
  margin-bottom: 0;
}

