.walletButton {
  border: none;
  margin: 5px 0;
  border-radius: 4px;
  background-color: rgba(211, 211, 211, 0.664);
  color: var(--brand-black);
  border: none;
  width: 100%;
  height: 96px;
  padding: 0 0 0 26px;
  text-align: left;
  font-size: 1.25rem;
}
.walletButton img {
  margin-right: 10px;
  border-radius: 5px;
}
.walletButton:hover,
.walletButton:active,
.walletButton:focus,
.walletButton:disabled {
  outline: none !important;
  box-shadow: none !important;
  color: var(--brand-black);
  background-color: var(--brand-white);
  border-color: var(--brand-black);
}
