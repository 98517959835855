.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(18, 25, 38, 0.8);
  backdrop-filter: blur(10px);
}

.content {
  max-height: 50vh;
  overflow-y: hidden;
}

.modal {
  font-family: 'DM Sans', sans-serif;
  font-weight: bold;
  position: fixed;
  top: 17vh;
  left: 10%;
  z-index: 100;
  background-color: var(--brand-white);
  padding: 32px;
  text-align: left;
  border-radius: 4px;
  left: calc(50% - 17.5rem);
  width: 35rem;
}

.modal .header {
  display: flex;
}

.modal .title {
  font-family: 'DM Sans';
  display: flex;
  flex-direction: column;
}

.modal .title h2 {
  font-size: 24px;
  margin-top: 0.5rem;
  color: rgba(140, 141, 146, 1);
}

.modal .title h1 {
  line-height: 42px;
  height: 2rem;
  font-size: 42px;
}

.closeBtnWrapper {
  padding: 1rem 2rem;
  display: flex;
  justify-content: flex-end;
}

.closeBtn {
  border-radius: 4px;
  background-color: #EEF2F6;
  border: 1px solid #EEF2F6;
  transition: all 0.2s ease-in-out;
  position: absolute;
  top: 2rem;
  right: 2rem;
  width: 44px;
  height: 44px;
  padding-top: 0;
}
.closeBtn:hover {
  color: var(--brand-black);
  background-color: var(--brand-white);
  border-color: var(--brand-black);
}


@media (max-width: 992px) {
  .backdrop {
    background: rgba(0, 0, 0, 0.74);
  }

  .content {
    max-height: 100%;
    height: 100%;
  }

  .modal {
    top: auto;
    left: 0;
    bottom: 0;
    width: 100%;
    max-height: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: none;
  }
}
