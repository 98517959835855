.bidWrapper {
  margin: 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}

.bidBtn {
  font-family: 'DM Sans';
  margin-top: 12px;
  margin-top: 9px;
  width: 100%;
  padding: 10px 16px;
  height: 56px;
  color: white;
  font-weight: normal;
  letter-spacing: normal;
  font-size: 19px;
  transition: all 0.2s ease-in-out;
  outline: none !important;
  box-shadow: none !important;
}

.bidBtn:disabled {
  cursor: not-allowed;
}

.westBtn,
.westBtn:disabled {
  background-color: var(--brand-color-red) !important;
  border: 1px solid var(--brand-color-red) !important;
}

.westBtn:hover,
.westBtn:active,
.westBtn:focus {
  background-color: var(--brand-white) !important;
  color: var(--brand-color-red) !important;
}

.eastBtn,
.eastBtn:disabled  {
  background-color: var(--brand-color-blue) !important;
  border: 1px solid var(--brand-color-blue) !important;
}

.eastBtn:hover,
.eastBtn:active,
.eastBtn:focus{
  background-color: var(--brand-white) !important;
  color: var(--brand-color-blue) !important;
}

.minBidCopy {
  padding-top: 0;
  font-size: small;
  margin-left: 0.25rem;
  margin-bottom: 0.3rem;
}

.bidInputGroup {
  margin-top: 15px;
  margin-bottom: 15px;
}
.bidInput {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  width: 100%;
  height: 72px;
  color: #616161;
  box-shadow: inset 0px 0px 0px 1px transparent;
  background-color: #EEF2F6;
  outline: none !important;
  box-shadow: none !important;
  font-family: 'DM Sans';
  font-weight: bold;
  font-size: 1.3rem;
  transition: all 0.2s ease-in-out;
  border: 1px solid #f9f9f9;
  border-radius: var(--default-border-radius) !important;
  padding-left: 5%;
}

.bidInput:focus {
  box-shadow: inset 0px 0px 0px 1px var(--brand-white) !important;
  color: var(--brand-warm-light-text);
}

.customPlaceholder {
  color: var(--brand-cool-light-text);
  font-family: 'DM Sans';
  font-weight: bold;
  z-index: 3;
  user-select: none;
}

.customPlaceholderBidAmt {
  position: absolute;
  top: 30%;
  left: 20px;
  color: var(--brand-bg-gray-400);
  font-family: 'DM Sans';
  font-weight: bold;
  font-size: 1.3rem;
  z-index: 1;
  pointer-events: none;
}
.etherSymbol {
  font-family: sans-serif;
}

.activityRow .form-floating {
  display: flex !important;
  flex-flow: row nowrap !important;
  justify-content: space-between !important;
}

.bidBtnAuctionEnded {
  width: 100%;
  height: 3rem;
  color: white;
  border: transparent;
  background-color: var(--brand-orange);
  font-weight: bold;
  font-family: 'DM Sans';
  font-size: 18px;
  border-radius: 10px;
}

.bidBtnAuctionEnded:hover,
.bidBtnAuctionEnded:active,
.bidBtnAuctionEnded:focus,
.bidBtnAuctionEnded:disabled {
  background-color: gray !important;
  color: rgb(209, 207, 207);
  outline: none !important;
  box-shadow: none;
}

.bidInputAuctionEnded {
  display: none;
}

.bidInput:focus {
  outline: none;
}

/* REMOVE UP/DOWN ARROWS FROM INPUT */
/* Chrome, Safari, Edge, Opera */
.bidInput::-webkit-outer-spin-button,
.bidInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.bidInput[type='number'] {
  -moz-appearance: textfield;
}
.modalButton {
  background-color: #EEF2F6 !important;
  color: var(--brand-bg-gray) !important;
  font-size: 1.25rem;
  border: 1px solid #EEF2F6;
  margin-top: 30px !important;
  margin-bottom: -32px;
}
.modalButton:hover {
  color: var(--brand-black) !important;
  background-color: var(--brand-white) !important;
  border-color: var(--brand-black) !important;
}
.modalButton div {
  margin-right: 15px;
}
@media (max-width: 992px) {
  .bidInput {
    border: none;
    border: 1px solid #8c8d9275;
  }

  .customPlaceholder {
    left: 40%;
  }
  
  .emergencySettleWrapper {
    text-align: center;
  }

  .voteForNextNounBtnWrapper {
    width: 100%;
  }
}

.emergencySettleButton {
  color: var(--brand-dark-red);
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}
.connectToSettle {
  color: var(--brand-cool-accent);
}
.waitSettle {
  background-color: var(--brand-bg-gray-light);
  border-radius: var(--default-border-radius);
  margin-top: 16px;
  height: 152px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: var(--brand-bg-gray-400);
}
.waitSettle h1 {
  font-size: 1.5rem;
  font-weight: normal;
  margin: 0;
}
.waitSettle p {
  color: var(--brand-warm-light-text);
  font-size: 1.25rem;
  margin: 0;
  padding: 0 10%;
}