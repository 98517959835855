.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(18, 25, 38, 0.8);
  backdrop-filter: blur(10px);
}

.content {
  max-height: 60vh;
  overflow-y: hidden;
}

.modal {
  font-family: 'DM Sans', sans-serif;
  font-weight: bold;
  position: fixed;
  top: 15vh;
  left: 10%;
  z-index: 100;
  background-color: rgba(244, 244, 248, 1);
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  left: calc(50% - 20rem);
  width: 40rem;
  background-color: var(--brand-white);
}

.modal .header {
  background-color: var(--brand-color-blue);
  display: flex;
  padding: 32px;
  position: relative;
}
.modal.eastAuction .header {
  background-color: var(--brand-color-blue);
}
.modal.westAuction .header {
  background-color: var(--brand-color-red);
}

.modal .title {
  font-family: 'DM Sans';
  display: flex;
  flex-direction: column;
}

.modal .title h1 {
  font-size: 2rem;
  color: var(--brand-white);
}
.modal .title h2 {
  font-size: 1.5rem;
  color: var(--brand-white);
}

.nounWrapper {
  height: 200px;
  width: 200px;
  margin-right: 1rem;
}

.bidWrapper {
  overflow-y: scroll;
  box-shadow: inset 0px -12px 16px rgba(0, 0, 0, 0.08);
  height: 35vh;
  padding: 32px;
}

.bidWrapper ul {
  list-style: none;
  padding: 0;
}

.closeBtnWrapper {
  padding: 1rem 2rem;
  display: flex;
  justify-content: flex-end;
}

.closeBtn {
  z-index: 100;
  right: 32px;
  top: 32px;
  position: absolute;
  height: 40px;
  width: 40px;
  border-radius: 4px;
  transition: all 0.125s ease-in-out;
  background-color: transparent;
  border: 1px solid var(--brand-white);
  color: var(--brand-white);
}

.closeBtn:hover {
  cursor: pointer;
}

.closeBtn .icon {
  height: 24px;
  width: 24px;
}

.nullStateText {
  font-size: 24px;
  text-align: center;
  margin-top: 3rem;
}

.nounImage {
  max-height: none;
  min-height: auto;
}
.nounImage img {
  max-height: none;
  min-height: auto;
  background-color: #E3E8EF;
  border-radius: 4px;
  max-width: 100%;
}

.sideTag {
  border: 1px solid var(--brand-white);
  color: var(--brand-white);
  text-transform: uppercase;
  font-size: 14px;
  font-weight: normal;
  border-radius: 48px;
  display: inline-block;
  margin: 0 auto;
  padding: 7px 16px;
}

@media (max-width: 992px) {
  .backdrop {
    background: rgba(0, 0, 0, 0.74);
  }

  .content {
    max-height: 100%;
    height: 100%;
  }

  .modal {
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    max-height: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: none;
  }

  .bidWrapper {
    box-shadow: none;
    border-radius: 0;
    padding: 1rem;
    height: 100%;
  }

  .nullStateText {
    color: white;
  }
  .nounWrapper {
    height: 100px;
    width: 100px;
    margin-right: 1rem;
  }
  
  .modal .title h1 {
    font-size: 1.5rem;
  }
  .modal .title h2 {
    font-size: 1rem;
  }
  .modal .header {
    height: 165px;
  }
  .bidWrapper {
    height: calc(100vh - 165px);
  }
}
