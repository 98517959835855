.auctionTitleAndNavContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Fix Firefox navigation arrow alignment issues */
@-moz-document url-prefix() {
  .auctionTitleAndNavContainer {
    display: flex;
    align-items: center;
  }
}
