.wrapper {
  padding: 1rem 1rem;
  color: var(--brand-black);
  align-self: flex-start !important;
}

.informationRow {
  margin-bottom: 0.5rem;
}

h1 {
  font-size: 3rem;
  font-weight: 700;
}

h2 {
  font-size: 2.5rem;
}

h4 {
  font-size: 1rem;
  font-weight: 400;
}

.modalHeader {
  border-bottom: none;
  justify-content: flex-start;
  align-items: center;
}

.modalTitleWrapper {
  color: var(--brand-black);
}

.modalTitleWrapper h1 {
  font-size: xx-large;
  margin-left: 2rem;
}

.modalHeader button {
  align-self: flex-start;
}

.modalHeaderNounImgWrapper {
  width: 150px;
  height: 150px;
}
.activityCustomRow {
  display: flex;
}
.section h4 {
  font-family: 'DM Sans';
  font-size: 17px;
  line-height: 25px;
}

.section h2 {
  font-family: 'DM Sans';
  font-weight: bold;
  font-size: 32px;
}

/* Fix Firefox navigation arrow alignment issues */
@-moz-document url-prefix() {
  .colAlignCenter {
    display: flex;
    align-items: center;
  }
}

.fomoNounsLink {
  margin-top: 0rem;
  margin-bottom: 1rem;
  transition: 0.2s ease-in-out;
}

.fomoNounsLink svg {
  opacity: 0.6;
}

.fomoNounsLink a,
.fomoNounsLink:active a {
  font-family: 'DM Sans', sans-serif;
  font-weight: 500;
  padding-left: 0.5rem;
  text-decoration: none;
  opacity: 1;
  color: rgb(95, 95, 95);
}

.fomoNounsLink:hover,
.fomoNounsLink:hover a {
  text-decoration: none;
  color: black;
  opacity: 1;
}

.bidHistorySection {
  background-color: var(--brand-bg-gray-light);
  border-radius: var(--default-border-radius);
  height: 296px;
  padding: 24px;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.bidHistorySection.noMargin {
  margin: 0;
  padding: 0;
  height: auto;
}

.sideTitle {
 font-size: 1.8rem;
 margin-bottom: 0;
}
.eastTitle {
  color: var(--brand-color-blue);
}
.westTitle {
  color: var(--brand-color-red);
}
.navSide {
  display: flex;
  align-items: center;
}
@media screen and (min-width: 480px) {
  .sideTitle {
    font-size: 3rem;
   }
}