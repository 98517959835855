.nounInfoRowBirthday {
  font-family: 'DM Sans';
  font-weight: bold;
  font-size: 1.25rem;
  color: var(--brand-white);
}
.title {
  font-family: 'DM Sans';
  font-weight: bold;
  font-size: 1.25rem;
  color: var(--brand-warm-light-text);
}