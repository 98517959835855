.img {
  image-rendering: pixelated;
  image-rendering: -moz-crisp-edges;
  /* width: 100%; */
  height: auto;
  max-height: 38vh;
  min-height: 300px;
  vertical-align: middle;
}

.imgWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.circular {
  border-radius: 4px;
  min-height: unset;
}

.delegateViewCircular {
  width: 100%;
  height: 100%;  
  border-radius: 4px;
  min-height: unset;
  max-height: unset;
  display: inline-block;
}

.circleWithBorder {
  border-radius: 4px !important;
  border: 2px solid #ffffff;
  min-height: unset;
}

.rounded {
  border-radius: 15px;
}

.circularNounWrapper {
  width: 64px;
  height: 64px;
  border-radius: 4px !important;
  margin-bottom:8px;
  background-color: #364152;
  max-width: 100%;
  max-height: 100%;
}
.withImage {
  background-color: #E3E8EF;
}

.delegateViewCircularNounWrapper {
  width: 64px;
  height: 64px;
  border-radius: 4px !important;
  margin-bottom:8px;
}

@media (max-width: 1200px) {
  .circularNounWrapper {
    height: 70%;
    width: 70%;
  }
}
