.arrow {
  -webkit-appearance: none;
  padding: 0;
  display: inline-block;
  width: 35px;
  height: 35px;
  border: none;
  background-size: contain;
  background-repeat: no-repeat;
  font-size: large;
  border-radius: 4px;
  color: var(--brand-white);
  background-color: var(--brand-gray-background);
  font-weight: normal;
  margin-left: 6px;
}
.arrow.leftArrowWarm,
.arrow.rightArrowWarm {
  color: var(--brand-warm-dark-text);
  background-color: var(--brand-warm-accent);
}
.arrow[disabled] {
  opacity: 0.5;
}
@media screen and (min-width: 480px) {
  .arrow {
    width: 50px;
    height: 50px;
    margin-left: 15px;
  }
}
/* Disable hover on mobile because it was leading to
buttons looking disabled when they were not */
@media (min-width: 992px) {
  .leftArrowWarm:hover,
  .leftArrowCool:hover,
  .rightArrowWarm:hover,
  .rightArrowCool:hover {
    opacity: 0.9;
    background-color: var(--brand-gray-hover);
  }
}

.leftArrowWarm:disabled,
.leftArrowCool:disabled,
.rightArrowWarm:disabled,
.rightArrowCool:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

@-moz-document url-prefix() {
  .navArrowsContainer {
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
}
