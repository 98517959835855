.section {
  padding: 0 18px 0 30px;
}
.cardWrapper {
  margin-bottom: 24px;
}
.card {
  background-color: var(--brand-bg-gray-light);
  padding: 28px;
  width: 100%;
  height: 100%;
  border-radius: 4px;
}
.image {
  margin-bottom: 32px;
}
.treasuryWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.heading {
  display: inline-block;
  font-weight: bold;
  font-size: 3rem;
}

.headerRow span {
  color: var(--brand-white);
  font-size: 25px;
  font-family: 'DM Sans';
  display: block;
}

.headerRow h1 {
  color: var(--brand-white);
  font-size: 40px;
  font-family: 'DM Sans';
}

.subheading {
  color: var(--brand-dark-green);
  font-family: 'DM Sans';
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 24px;
}

.boldText {
  font-family: 'DM Sans';
  font-weight: bold;
}

p {
  padding-top: 1rem;
}

.treasuryCards {
  border-radius: 4px;
  text-align: center;
  padding: 16px;
  background-color: var(--brand-color-blue);
  color: var(--brand-white);
  font-size: 50px;
  line-height: 64px;
  font-weight: 700;
  margin-bottom: 12px;
  display: flex;
  align-content: center;
  justify-content: center;
  font-family: 'DM Sans';
}
.treasuryCardRed {
  background-color: var(--brand-color-red);
}

.treasuryAmtWrapper {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.ethTreasuryAmt {
  display: flex;
  height: 3rem;
  min-width: 9rem;
  padding-top: 0.2rem;
}

.ethTreasuryAmt h1 {
  font-size: 36px;
  font-family: 'DM Sans';
}

.usdBalance {
  font-size: 36px;
  font-family: 'DM Sans';
}

.ethSymbol {
  font-family: sans-serif !important;
  margin-right: 0.5rem;
}

.usdBalance {
  color: var(--brand-gray-light-text);
  font-family: 'DM Sans';
}

.treasuryInfoText {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  font-family: 'DM Sans';
  font-weight: 500;
}

@media screen and (min-width: 992px) {

.card {
  padding: 56px;
}
}