.section {
  word-wrap: break-word; 
  padding: 0 30px;
  margin-top: 2rem;
}
.votePage a {
  color: var(--brand-white);
  font-weight: 700;
}

.proposal {
  margin-top: 1em;
  /* background-color: white; */
}

.backArrow {
  height: 1rem;
}

.votingButton {
  margin-top: 1rem;
}

.voteCountCard {
  margin-top: 1rem;
}

.proposalId {
  margin: 1rem 0;
}

.voteCountCard p {
  display: flex;
  justify-content: space-between;
}


.section h5 {
  font-size: 1.7rem;
  margin-top: 1rem;
  font-family: 'DM Sans';
}

.voterIneligibleAlert {
  margin: 1rem 0 0 0;
}

.blockRestrictionAlert {
  margin: 1rem 0 0 0;
}

.transitionStateButton {
  background-color: var(--brand-gray-background);
  height: 50px;
  border-radius: 4px;
  font-family: 'DM Sans';
  font-weight: bold;
  font-size: 24px;
  transition: all 0.125s ease-in-out;
}

.transitionStateButton:active,
.transitionStateButton:focus,
.transitionStateButton:hover {
  opacity: 0.8;
  cursor: pointer;
  background-color: var(--brand-gray-background) !important;
}

.transitionStateButtonSection {
  border-top: 0px;
}

.spinner {
  margin-left: auto;
  margin-right: auto;
  color: var(--brand-gray-light-text);
}

/* Info section stuff */
.voteInfoCard {
  background-color: #202939;
  margin-top: 1rem;
  padding: 0.5rem;
  border-radius: 12px;
}

.voteMetadataRow {
  display: flex;
  justify-content: space-between;
}

.voteMetadataRow h1 {
  font-size: 20px;
  color: var(--brand-gray-light-text);
  font-family: 'DM Sans';
}

.voteMetadataRow span {
  font-size: 14px;
  font-family: 'DM Sans';
  color: var(--brand-gray-light-text);
}

.voteMetadataRow h3 {
  font-size: 18px;
  font-family: 'DM Sans';
  font-weight: bold;
}

.voteMetadataRowTitle {
  margin-top: 0.5rem;
  width: max-content;
}

.voteMetadataTime {
  min-width: fit-content;
  text-align: right;
}

.snapshotBlock {
  text-align: right;
}

.thresholdInfo {
  text-align: right;
}

.toggleDelegateVoteView {
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
  opacity: 0.5;
  font-size: 14px;
  cursor: pointer;
  transition: ease-in-out 125ms;
  width: fit-content;
  margin-left: 0.1rem;
}

.toggleDelegateVoteView:hover {
  text-decoration: underline;
}

@media (max-width: 1200px) {
  .toggleDelegateVoteView {
    display: none;
  }
}
