.wrapper {
  height: 56px;
  border-radius: 4px;
  font-size: 1.25rem;
  font-family: 'DM Sans';
  font-weight: bold;
  padding: 16px 6px 16px 16px;
  transition: all 0.125s ease-in-out;
  box-shadow: none;
  color: var(--brand-white);
  background-color: var(--brand-bg-gray-light);
}
.whiteInfo {
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: var(--brand-white);
}

.whiteInfo:hover {
  background-color: #e2e3e8;
  color: black;
}

.coolInfo {
  color: var(--brand-white);
  background-color: var(--brand-gray-background);
}

.coolInfo:hover {
  background-color: var(--brand-bg-gray-light);
}

.warmInfo {
  color: #b6a9a7;
  background-color: #fdf9f9;
}

.warmInfo:hover {
  background-color: white;
  color: black;
}

.whiteInfoSelected {
  background-color: #f4f4f8;
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: #8c8d92;
}

.whiteInfoSelectedTop {
  background-color: #f4f4f8;
  border-top: 1.5px solid #e2e3e8;
  border-right: 1.5px solid #e2e3e8;
  border-left: 1.5px solid #e2e3e8;
  color: #8c8d92;
}

.whiteInfoSelectedBottom {
  background-color: #f4f4f8;
  border-bottom: 1.5px solid #e2e3e8;
  border-right: 1.5px solid #e2e3e8;
  border-left: 1.5px solid #e2e3e8;
  color: #8c8d92;
}

.dropdownActive {
  /* background-color: white; */
  color: var(--brand-white);
}
.coolInfoSelected {
  color: var(--brand-white) !important;
  background-color: var(--brand-gray-background);
  width: 88% !important;
  margin-left: 6%;
  border-radius: var(--default-border-radius);
  margin-bottom: 7px;
  font-size: 1.25rem;
  font-weight: normal;
}

.warmInfoSelected {
  background-color: #fdf9f9;
  color: rgba(142, 129, 127, 1);
}

.button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding-right: 14px;
}

.dropdownBtnContent {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-height: 18px;
  line-height: 20px;
  color: var(--brand-white) !important;
}

.arrowDown {
  margin-bottom: 0.40rem;
  margin-left: 15px;
}

.arrowUp {
  margin-left: 15px;
  margin-top: 0.5rem;
}

.button:hover {
  cursor: pointer;
}

.nounsNavLink,
.nounsNavLink:active,
.nounsNavLink:visited {
  font-family: 'DM Sans';
  font-weight: bold;
  font-size: 0.9rem;
  padding: 0.3rem 0.3rem !important;
  transition: all 0.125s ease-in-out;
}

.dropdownPrimaryText:hover {
  background-color: white;
  color: var(--brand-bg-gray) !important;
  transition: all 0.125s ease-in-out;
  cursor: pointer;
}

.desktopDropdown {
  background-color: var(--brand-bg-gray-light) !important;
  margin-top: 7px;
  margin-left: -2px;
  border: 0px !important;
  width: 96%;
  border-radius: var(--default-border-radius);
}
