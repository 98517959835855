.youCopy {
  margin-top: 0.25rem;
}

.link,
.link:hover,
.link:active {
  color: var(--brand-white);
  text-decoration: none;
  display: flex;
  cursor: pointer;
}

.verifyButton {
  height: 3rem;
  color: white;
  border: transparent;
  background-color: var(--brand-black);
  font-family: 'DM Sans';
  font-weight: bold;
  border-radius: 8px;
  font-size: 16px;
}

.verifyButton:hover,
.verifyButton:active,
.verifyButton:focus,
.verifyButton:disabled {
  background-color: gray !important;
  outline: none !important;
  box-shadow: none !important;
}

.section h4 {
  font-family: 'DM Sans';
  font-size: 1.25rem;
}

.section h2 {
  font-family: 'DM Sans';
  font-weight: bold;
  font-size: 32px;
  margin-bottom: 0;
}

.leftCol {
  font-family: 'DM Sans';
  padding: 0;
}

.leftCol h4 {
  font-weight: bold;
}

@media (max-width: 992px) {
  .section {
    justify-content: space-between;
  }

  .wrapper {
    margin-top: 0px;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .wrapper {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  .verifyButton {
    width: 100%;
    height: 3rem;
    border-radius: 10px;
    font-weight: bold;
    font-size: 18px;
  }

  .verifyLink {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}
