.wrapper {
  margin-top: 2px;
}

.youCopy {
  margin-top: 0.25rem;
}

.holderCopy {
  min-width: 250px;
}

.holderCopySimple {
  min-width: auto;
}

.section h4 {
  font-family: 'DM Sans';
  font-size: 18px;
  line-height: 27px;
}

.section h2 {
  font-family: 'DM Sans';
  font-weight: bold;
  font-size: 28px;
  margin-bottom: 0;
}

.leftCol {
  font-family: 'DM Sans';
}

.leftCol h4 {
  font-weight: bold;
  color: var(--brand-warm-light-text);
  margin-bottom: 0;
}

.link,
.link:hover,
.link:active {
  color: var(--brand-white);
  text-decoration: none;
  display: flex;
  cursor: pointer;
}

.holderContent {
  white-space: nowrap;
}