.bidHistoryWrapper {
  background-color: var(--brand-cool-accent);
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.bidHistoryWrapperCool {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  color: var(--brand-cool-light-text);
}

.bidHistoryWrapperWarm {
  display: flex;
  justify-content: flex-end;
  transition: all 0.2s ease-in-out;
  color: var(--brand-warm-light-text);
}

.bidHistory {
  font-family: 'DM Sans';
  color: var(--brand-white);
  width: 100%;
  height: 56px;
  font-weight: normal;
  letter-spacing: normal;
  font-size: 19px;
  background-color: var(--brand-gray-background);
  border: none;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}


.bidHistory:hover,
.bidHistory:active,
.bidHistory:focus {
  background-color: var(--brand-white);
  color: var(--brand-black);
}

.bidHistoryWrapperWarm:hover {
  color: var(--brand-color-warm);
}

.bidHistoryWrapperCool:hover {
  color: var(--brand-color-blue);
}

.bidHistoryWrapper:hover {
  background-color: var(--brand-gray-hover);
}
